<template>
  <div id="early_adopters">
    <img src="@/assets/images/early_adopters_bg.png" alt="" width="100%" />
    <main>
      <div class="goods_list" id="goods_list">
        <div
          class="goods_box"
          v-for="list in tastePriceList"
          :key="list.id"
          @click="
            $router.push({
              path: '/goodsDetails',
              query: {
                barcode: list.barcode,
                enterpriseId: list.enterpriseId,
              },
            })
          "
        >
          <div class="image_header">
            <img v-lazy="list.filePath" height="auto" />
            <div v-if="!list.inventory"><p>补货中</p></div>
          </div>
          <div class="info">
            <p class="name">{{ list.commodityName }}</p>
            <div>
              <p class="daily_price ellipsis">
                <span>日常售价</span
                ><span><i>¥</i>{{ list.retailPrice.toFixed(2) }}</span>
              </p>
              <p class="price ellipsis">
                <span>新人价</span
                ><span><i>¥</i>{{ list.price.toFixed(2) }}</span>
              </p>

              <button
                @click.stop="list.inventory ? onAddCartClicked(list) : ''"
                :class="list.existCart ? 'pick_on' : ''"
                :style="
                  !list.inventory
                    ? 'background:#ddd;color:#8e8e8e;border-color:#ddd'
                    : ''
                "
              >
                {{ list.existCart && list.inventory ? "已选" : "选它" }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <van-empty description="暂无商品" v-show="!tastePriceList.length" />
    </main>
    <functionButton :homePageBtn="true" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Dialog } from "vant";
import { WechatSharing } from "@/utils/sdk";

export default {
  name: "early-adopters",

  computed: {
    ...mapState("user", ["isLogin", "userInfo"]),
  },
  data() {
    return {
      tastePriceList: [],
    };
  },

  created() {
    if (this.$route.query.id) {
      // if (this.$route.query.id && !sessionStorage.getItem("userUuid")) {
      sessionStorage.setItem("userUuid", this.$route.query.id);
      // }
    } else {
      this.$router.replace({
        path: "earlyAdopters",
        query: {
          id: sessionStorage.getItem("userUuid")
            ? sessionStorage.getItem("userUuid")
            : undefined,
        },
      });
    }

    let shareInfo = {
      title: "正品汇商城，新人尝鲜价活动。",
      desc: "新用户注册，首次下单，任选1件超值新人专享商品，更有200元等值积分礼包等您来领取~",
      imgUrl: require("@/assets/images/logo.png"),
      link: window.location.hash,
    };
    WechatSharing(shareInfo);

    this.getTastePriceList();
  },
  methods: {
    async getTastePriceList() {
      this.Toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      const data = await this.$API.homePage.queryActivityList({
        object: {
          enableState: true,
          examineState: 1,
          state: "1",
          userId: this.isLogin ? this.userInfo.id : undefined,
        },
        pageNum: 0,
        pageSize: 0,
      });
      data.data.data.forEach((g) => {
        g.filePath = g.filePath.split(",")[0].endsWith(".mp4")
          ? g.filePath.split(",")[1]
          : g.filePath.split(",")[0];
      });
      this.tastePriceList = data.data.data;
      if (!this.tastePriceList.length) {
        Dialog.alert({
          message:
            "您不是新用户啦，会员登录首页领取200元等值积分，下单立享满减优惠，还有超级秒杀，只等您一键下单！",
          confirmButtonText: "回到首页",
        }).then(() => {
          // on close
          this.$router.replace("/");
        });
      }
      this.Toast.clear();

      // this.$nextTick(function () {
      //   this.waterFall();
      // });
    },
    async onAddCartClicked(goods) {
      // this.Toast({
      //   type: "success",
      //   message: "添加购物车成功",
      //   forbidClick: true,
      // });
      if (!this.isLogin) {
        this.$router.push({
          path: "/login",
          query: {
            redirect_uri: this.$route.fullPath,
          },
        });
        return;
      }
      if (goods.existCart) {
        this.Toast({
          type: "fail",
          message: "新人尝鲜价只能选一件哦",
          forbidClick: true,
          duration: 1000,
        });
        return;
      }

      await this.$API.order
        .InsertCusOrderCart({
          object: {
            attributeId: [],
            barcode: goods.barcode,
            commId: goods.commId,
            type: 1,
            typeId: goods.id,
            quantity: 1,
            storeId: goods.storeId,
            userId: this.userInfo.id,
          },
        })
        .then((res) => {
          this.$store.dispatch("user/getQuantity");
          this.Toast({
            type: "success",
            message: "选择成功",
            forbidClick: true,
            duration: 1000,
          });
          this.tastePriceList.forEach((goods) => {
            if (goods.existCart) goods.existCart = false;
          });
          goods.existCart = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    waterFall() {
      // 1. 设置container盒子的宽度
      //      注意：浏览器的可视区域的宽度 / 一个item元素的宽度 = 一行的排列的元素的个数
      let container = document.getElementById("goods_list");
      let item = document.getElementsByClassName("goods_box");
      // let item = [];
      // for (const node of container.children) {
      //   if (node.className === "goods_item_box") {
      //     item.push(node);
      //   }
      // }
      if (!item.length) return;
      //获取元素的宽度(含border，padding)
      let width = item[0].offsetWidth;
      //计算出浏览器窗口的宽度
      let clientWidth = document.documentElement.clientWidth;
      //计算出应该放几列（向下取整）
      let columnCount = Math.floor(clientWidth / width);
      //设置容器（父盒子）的宽度
      // container.style.width = columnCount * width + "px";
      let left =
        (container.offsetWidth - width * columnCount) / (columnCount - 1); //宽度间隔
      let height = 7.5; //高度间隔

      // 2.设置每一个item元素的排列位置
      //  第一行整体的top值都是0 后面的依次找上一行高度最小的容器，在它下面进行排列
      let hrr = [];
      for (let i = 0; i < item.length; i++) {
        //定位第一行的图片
        if (i < columnCount) {
          item[i].style.top = "0px";
          if (i) {
            item[i].style.left = i * (width + left) + "px";
          } else {
            item[i].style.left = "0px";
          }
          hrr.push(item[i].offsetHeight + height);
        } else {
          //第一行之后的
          //选择总高度最小的列
          let min = Math.min(...hrr);
          // console.log(...hrr);
          let index = hrr.indexOf(min);
          //将每个元素定位到当前总高度最小的列下
          //index为0在左边 为1在右边 2以此类推
          item[i].style.top = min + "px";
          if (index) {
            item[i].style.left = index * width + index * left + "px";
          } else {
            item[i].style.left = index * width + "px";
          }
          //当前定位的元素加入该列
          hrr[index] += item[i].offsetHeight + height;
        }
      }
      if (hrr.length < 2) {
        container.style.height = `${hrr[0]}px`;
        return;
      }
      if (hrr[0] >= hrr[1]) {
        container.style.height = `${hrr[0]}px`;
      } else {
        container.style.height = `${hrr[1]}px`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#early_adopters {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #f31d14;

  main {
    flex: 1;
    padding: 12px 15px 4px;
    background: #f31d14;
    .goods_list {
      // position: relative;
      .goods_box {
        // position: absolute;
        display: inline-block;
        width: 32%;
        background: #fff;
        margin-right: 2%;
        border-radius: 10px;
        margin-bottom: 8px;
        .image_header {
          position: relative;
          width: 100%;
          height: 0;
          padding-top: 100%;
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 10px 10px 0 0;
          }
          div {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba($color: #fff, $alpha: 0.3);
            p {
              background: rgba($color: #000000, $alpha: 0.5);
              color: #fff;
              line-height: 26px;
              padding: 0 10px;
              border-radius: 13px;
            }
          }
        }

        .info {
          padding: 12px 6px 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .name {
            color: #333333;
            line-height: 18px;
            margin-bottom: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            min-height: 54px;
          }
          .daily_price {
            display: flex;
            align-items: center;
            border: 1px solid #fb4e10;
            font-size: 12px;
            color: #fb4e10;
            padding: 1px 2px;
            border-radius: 2px;
            margin-bottom: 8px;
          }
          .price {
            display: flex;
            align-items: center;
            font-size: 12px;
            margin-bottom: 12px;

            span:nth-child(2) {
              color: #f91c12;
              font-size: 18px;
              font-weight: 800;
            }
          }
          .ellipsis {
            span:nth-child(1) {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
            span:nth-child(2) {
              flex: 1;
              text-align: end;
              i {
                font-size: 12px;
                font-style: normal;
                font-weight: normal;
                transform: scale(0.8);
              }
            }
          }
          button {
            background: linear-gradient(180deg, #f91c12 0%, #fb4e10 100%);
            border-radius: 16px;
            border: none;
            color: #ffffff;
            padding: 4px 18px;
            display: block;
            margin: 0 auto;
            border: 1px solid transparent;
          }
          .pick_on {
            background: #fff;
            color: #f31d14;
            border: 1px solid #f91c12;
          }
        }
      }
      .goods_box:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"early_adopters"}},[_c('img',{attrs:{"src":require("@/assets/images/early_adopters_bg.png"),"alt":"","width":"100%"}}),_c('main',[_c('div',{staticClass:"goods_list",attrs:{"id":"goods_list"}},_vm._l((_vm.tastePriceList),function(list){return _c('div',{key:list.id,staticClass:"goods_box",on:{"click":function($event){return _vm.$router.push({
            path: '/goodsDetails',
            query: {
              barcode: list.barcode,
              enterpriseId: list.enterpriseId,
            },
          })}}},[_c('div',{staticClass:"image_header"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(list.filePath),expression:"list.filePath"}],attrs:{"height":"auto"}}),(!list.inventory)?_c('div',[_c('p',[_vm._v("补货中")])]):_vm._e()]),_c('div',{staticClass:"info"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(list.commodityName))]),_c('div',[_c('p',{staticClass:"daily_price ellipsis"},[_c('span',[_vm._v("日常售价")]),_c('span',[_c('i',[_vm._v("¥")]),_vm._v(_vm._s(list.retailPrice.toFixed(2)))])]),_c('p',{staticClass:"price ellipsis"},[_c('span',[_vm._v("新人价")]),_c('span',[_c('i',[_vm._v("¥")]),_vm._v(_vm._s(list.price.toFixed(2)))])]),_c('button',{class:list.existCart ? 'pick_on' : '',style:(!list.inventory
                  ? 'background:#ddd;color:#8e8e8e;border-color:#ddd'
                  : ''),on:{"click":function($event){$event.stopPropagation();list.inventory ? _vm.onAddCartClicked(list) : ''}}},[_vm._v(" "+_vm._s(list.existCart && list.inventory ? "已选" : "选它")+" ")])])])])}),0),_c('van-empty',{directives:[{name:"show",rawName:"v-show",value:(!_vm.tastePriceList.length),expression:"!tastePriceList.length"}],attrs:{"description":"暂无商品"}})],1),_c('functionButton',{attrs:{"homePageBtn":true}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }